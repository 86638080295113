const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://taylanunal.com',
  title: 'TU.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Taylan Unal',
  role: 'Solutions Architect at AWS',
  description:
    "I love exploring new technologies, studying finance & crypto, and learning about cars! I'm passionate about software development, cloud computing, and problem solving.",
  resume: '',
  social: {
    linkedin: 'https://linkedin.com/in/tunal',
    github: 'https://github.com/taylanu',
  },
}

const experience = [
  // projects can be added and removed. if there are no projects, Projects section won't show up
  {
    name: 'Solutions Architect (TechU)',
    company: 'Amazon Web Services',
    year: "July 2021-Present",
    description:
      "Helping customers build cloud infrastructure and applications using the AWS Cloud to address their business problems, understand best practices around cloud-based solutions, and how to migrate existing workloads to the cloud.",
    stack: ['AWS', 'Solutions Architecture', 'Cloud'],
  },
  {
    name: 'Solutions Architect Intern',
    company: 'Amazon Web Services',
    year: "June 2020-September 2020",
    description: "Developed Microservices on the AWS Cloud using NodeJS (ExpressJS) and Python (Flask). Used Open Policy Agent (OPA) to write policies and perform Data and API Authorization. Created Proof of Concept for Policy Based Authorization on OSDU.",
    stack: ['AWS', 'ECS', 'Containers', 'NodeJS', 'ExpressJS', 'Python', 'OPA'],
  },
]

const education = [
  {
    school: 'Penn State University',
    major: 'Computer Science B.S.',
    minor: 'Economics',
    year: 'June 2017-May 2021',
    description: 'Graduated from the College of Engineering with a Bachelors of Science in Computer Science with a minor in Economics'
  }
]

const projects = [
  // If there are no projects, Projects section will be hidden. Projects can be added and removed here.
  {
    name: 'ArboretumID',
    description:
      "ArborID provides visitors a brand new mobile-first experience using the latest iOS technologies to provide a new way to interact with the collections at The Penn State Arboretum.",
    stack: ['Swift', 'SwiftUI', 'CoreML', 'iOS'],
    sourceCode: 'https://github.com/taylanu/ArboretumID',
    livePreview: 'https://github.com/taylanu/ArboretumID/blob/main/ArboretumID.pdf'
  },
  {
    name: 'CS193P',
    description: "Continuing to learn about iOS Development through the Stanford CS193P course.",
    stack: ['Swift', 'SwiftUI', 'iOS'],
    sourceCode: 'https://github.com/taylanu/CS193P-TU',
    livePreview: 'https://github.com/taylanu/CS193P-TU/blob/main/README.md'
  }
]

const certifications = [
  {
    name: 'AWS Certified Cloud Practitioner',
    imageurl: 'https://images.credly.com/images/68468004-5a85-4f3b-bc58-590773979486/AWS-CloudPractitioner-2020.png',
    verification: 'https://www.credly.com/badges/1f281afc-3307-405f-a47d-9859689ca8bd/public_url'
  },
  {
    name: 'AWS Certified Solutions Architect-Associate',
    imageurl: 'https://images.credly.com/size/680x680/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png',
    verification: 'https://www.credly.com/badges/882acfa7-5f10-48ed-97a1-fa793095a68d/public_url'
  },
  {
    name: 'AWS Certified Developer-Associate',
    imageurl: 'https://images.credly.com/size/680x680/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png',
    verification: 'https://www.credly.com/badges/af4cd44e-1e28-46f9-90e6-28570bd0144c/public_url'
  },
  {
    name: 'AWS Certified SysOps Administrator-Associate',
    imageurl: 'https://images.credly.com/size/680x680/images/f0d3fbb9-bfa7-4017-9989-7bde8eaf42b1/image.png',
    verification: 'https://www.credly.com/badges/145d99df-5f7a-45b9-9c16-6739f715a3e9/public_url'
  }  
]

const skills = [
  // skills can be added or removed, if there are no skills, section won't show up
  'Node.js/Express.js',
  'Python',
  'C',
  'Java',
  'iOS Development',
  'Microservices',
  'Amazon Web Services (AWS)'
]

export { header, about, education, experience, projects, certifications, skills}
