import uniqid from 'uniqid'
import LaunchIcon from '@material-ui/icons/Launch'
import './CertificationsContainer.css'

// Resume Container defines each of the boxes within the experience section.
const CertificationsContainer = ({ certification }) => (
  <div className='certifications'>
    <img src={certification.imageurl} class="badge--icon" />
    <h4> {certification.name} </h4>
    <a href={certification.verification} aria-label='live preview' className='link link--icon'>
      <LaunchIcon />
    </a>

    {certification.stack && (
      <ul className='certifications__stack'>
      {certification.stack.map((item) => (
        <li key={uniqid()} className='certifications__stack-item'>
          {item}
        </li>
      ))}
      </ul>
    )}

  </div>
)

export default CertificationsContainer
