import uniqid from 'uniqid'
import { experience } from '../../portfolio'
import ExperienceContainer from './ExperienceContainer'
import './Experience.css'

const Experience = () => {
  if (!experience.length) return null

  return (
    <section id='resume' className='section resume'>
      <h2 className='section__title'>Experience</h2>

      <div className='resume__grid'>
        {experience.map((experience) => (
          <ExperienceContainer key={uniqid()} experience={experience}/>
        ))}
      </div>
    </section>
  )
}

export default Experience
