import uniqid from 'uniqid'
import './ExperienceContainer.css'

// Resume Container defines each of the boxes within the experience section.
const ExperienceContainer = ({ experience }) => (
  <div className='resume'>
    <h3>{experience.name}</h3>
    <h4>{experience.company}</h4>
    <p>{experience.year}</p>

    <p className='resume__description'>{experience.description}</p>
    {experience.stack && (
      <ul className='resume__stack'>
        {experience.stack.map((item) => (
          <li key={uniqid()} className='resume__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}
    
  </div>
)

export default ExperienceContainer
