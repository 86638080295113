import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { about } from '../../portfolio'
// import ResumePDF from '../../pdf/UnalResume.pdf';
import './About.css'

const About = () => {
  const { name, role, description, resume, social } = about

  return (
    <div className='about center'>
      {name && (
        <h1>
          Hi, my name's <span className='about__name'>{name}.</span>
        </h1>
      )}

      {/* Add 'A' or 'An' before role to make it make sense */}
      {role && <h2 className='about__role'>I'm a {role}.</h2>} 
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {/* {(
          <a href={resume}>
              <a href={ResumePDF} without rel="noopener noreferrer" target="_blank" class='btn btn--outline'>  
                Resume
              </a>
          </a>
        )} */}

        {social && (
          <>
            {social.github && (
              <a href={social.github} aria-label='github'className='link link--icon'>
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a href={social.linkedin} aria-label='linkedin' className='link link--icon'>
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
