import uniqid from 'uniqid'
import { certifications } from '../../portfolio'
import CertificationsContainer from './CertificationsContainer'
import './Certifications.css'

// Base Certifications section off of Resume containers.
const Certifications = () => {
  if (!certifications.length) return null

  return (
    <section id='certifications' className='section certifications'>
      <h2 className='section__title'>Certifications</h2>
      
      <div className='certifications__grid'>
        {certifications.map((certification) => (
          <CertificationsContainer key={uniqid()} certification={certification}/>
        ))}
      </div>
    </section>
  )
}

export default Certifications
