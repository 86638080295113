import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <p> 
      Created by Taylan Unal, adapted from <a href='https://github.com/rajshekhar26/cleanfolio' className='link footer__link'>Simplefolio </a>
    </p>
  </footer>
)

export default Footer
